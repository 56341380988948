import {
  AddressConfig,
  EducationConfig,
  EmployerConfig,
  ReferenceConfig,
  ProfileChecksConfig
} from "./applicant.class";

export class PageSettings {
  pageNo: number;
  pageSize: number;
}

export class Enterprise extends PageSettings {
  id: number;
  name: string;
  isDeleted: boolean;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  city: string;
  addressStateCode: string;
  addressPostalCode: string;
  contactPhone: string;
  contactEmail: string;
  contactFax: string;
  enterpriseCode: string;
  isTest: boolean;
  contactFirstName: string;
  contactLastName: string;
  statusTypeID: number;
  enterPriseTypeID: string;
  countryId: number;
  stateId: number;
  gstTypeId: number;
  gstnumber: string;
  pannumber: string;
  cinnumber: string;
  corporateAddress: string;
  filePath: string;
  isSendMailCandidate: boolean;
  isSendReminderMail: boolean;
  reminderEmailDay: number;
  idChecks: Array<number>;
  instantCheckTypeIds: Array<number>;
  lstProfileChecksConfigs: ProfileChecksConfig[];
}
export class LstChecks {
  lstChecks: ProfileChecksConfig[];
}

export class EnterpriseTypes {
  id: number;
  name: string;
}

export class Holiday {
  id: number;
  date: string;
  title: string;
  description: string;
  createdDate: string;
  holidayCategoryName: string;
  holidayCategoryId: number;
  pageNo: number;
  pageSize: number;
}

export class EnterpriseProfileStatusModel {
  enterpriseId: number;
  profileInProgressByEmployee: number;
  profileSubmittedByEmployee: number;
  profileInProgress: number;
  profileReferbacktoemployee: number;
  profileReferbacktoHR: number;
  profileProcessInitiated: number;
  profileProcessApproved: number;
  profileProcessInProgress: number;
  profileProcessCancelled: number;
  profileProcessCompleted: number;
  pageNo: number;
  pageSize: number;
  assignedEnterprises: Array<number>;
  fromDate:Date;
  toDate:Date;
}

export class EnterpriseCheckStatusModel {
  checkType: string;
  enterprise: string;
  assignedToName: string;
  nonVendor: number;
  caseReInitiate: number;
  closedbyVendor: number;
  closed: number;
  requestforSiteCheck: number;
  assigntoVendor: number;
  amountApproval: number;
  internalUpdate: number;
  clarification: number;
  insuffCleared: number;
  insuffRaised: number;
  universityGenunityCheck: number;
  nottobeInitiated: number;
  followupbyEmail: number;
  followupbyphone: number;
  rocCheck: number;
  caseInProgress: number;
  caseInitiated: number;
  pageNo: number;
  pageSize: number;
}

export class UserSessionList {
  userName: string;
  expiryDate: string;
  createdDate: string;
  isExpired: boolean;
  browserDetails: string;
  pageNo: number;
  pageSize: number;
}
export class OTPRequest {
  otp: string;
}

export class ValidateUserLoginRequest {
  userName: string;
  password: string;
  browserDetails: string;
}

export class ValidateUserLoginResponse {
  uniqueId: string;
  isAuthenticated: string;
  userTypeId: string;
}
export class ValidateOtpRequest {
  uniqueId: string;
  otp: string;
  browserDetails: string;
}

export class Location {
  id: number;
  enterpriseId: number;
  location: string;
  city: string;
  state: string;
  country: string;
  pinCode: string;
  createdDate: string;
  pageNo: number;
  pageSize: number;
  enterpriseName: string;
  gst: string;
  gstTypeId: number;
}

export class Project {
  id: number;
  enterpriseId: number;
  name: string;
  description: string;
  createdDate: string;
  pageNo: number;
  pageSize: number;
  enterpriseName: string;
}

export class ProjectLocationsResponse {
  id: number;
  projectId: number;
  locationId: number;
  ccMails: string;
  bccMails: string;
}


export class ProjectLocationSearch {
  enterpriseId: number;
  location: string;
  project: string;
  pageNo: number;
  pageSize: number;
  enterpriseName: string;
}

export class ProjectLocationModel {
  id: number;
  locationId: number;
  enterpriseId: number;
  projectId: number;
  locationName: number;
  projectName: number;
  ccMail: string;
  bccMail: string;
}

export interface ICommonBaseResponse {
  status: boolean,
  statusMessage: string
}

export class EnterpriseSettings {
  enterpriseId: number
  enterpriseCode: string
  settings: Setting[]
}

export class Setting {
  key: string;
  value: string;
}
