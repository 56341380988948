export const commonConstants = {
  MOBILE_NUMBER_PATTERN: "^[0-9+]{10,20}$",
  EMAIL_PATTERN: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$",
  NUMBER_PATTERN: "^[+0-9]+"
};
export const appGlobalConstants = {
  logoUrl: "../assets/images/Logo-210-x-48.png"
};
export enum userTypesEnum {
  SUPER_ADMIN = 1,
  INFOQUEST_USER = 2,
  ENTERPRISE_USER = 3,
  PROFILE_USER = 4,
  DataEntry = 5,
  Researcher_Team = 6,
  Vendor_Team = 7,
  Client_Co_Ordinator = 8,
  Report_Writing = 9,
  Quality_Team = 10,
  Sales_Team = 11
}
export enum roleTypesEnum {
  ENTERPRISE_Role = 18
}

export enum mailPriorityTypesEnum {
  HIGH = 1,
  MEDIAM = 2,
  LOW = 3
}
export enum mailStatusTypesEnum {
  READY_TO_SEND = 1,
  SUCCESS = 2,
  FAILED = 3
}
export enum ExcelMaxPageZizeEnum {
  PageSize = 1000000
}
export const lstMonths = [
  { name: 'Jan', value: 1 },
  { name: 'Feb', value: 2 },
  { name: 'Mar', value: 3 },
  { name: 'Apr', value: 4 },
  { name: 'May', value: 5 },
  { name: 'Jun', value: 6 },
  { name: 'Jul', value: 7 },
  { name: 'Aug', value: 8 },
  { name: 'Sep', value: 9 },
  { name: 'Oct', value: 10 },
  { name: 'Nov', value: 11 },
  { name: 'Dec', value: 12 }
]

export const clientBillingStatus = [
  { id: 1, name: "Initiated" },
  { id: 2, name: "Pending" },
  { id: 3, name: "Approved" },
  { id: 4, name: "Rejected" },
  { id: 5, name: "Invoice Generated" },
]
export enum findUANTypesEnum {
  UAN = 1,
  PAN = 2,
  Aadhar = 3,
  MobileNo = 4
}
