import { GlobalChecksTypesEnum } from "../constants/ProfileCheckTypes.const";

export class PageSettings {
  pageNo: number;
  pageSize: number;
}

export class IdentityProofs {
  isValid: boolean;
  lstIdentityProofs: IdentityProof[];
}

export class IdentityChecks {
  profileId: number;
  isPanMandatory: boolean;
  passportDetails: ProfilePassportDetails = new ProfilePassportDetails();
  panDetails: ProfilePanDetails = new ProfilePanDetails();
  lstIdentityProofs: IdentityProof[];
}
export class IdentityChecksV2 {
  profileId: number;
  isPanMandatory: boolean;
  passportDetails: ProfilePassportDetails = new ProfilePassportDetails();
  panDetails: ProfilePanDetails = new ProfilePanDetails();
  lstIdentityProofs: IdentityProof[];
  identityTypeIds: number[]
}
export class ProfilePanDetails {
  id: number;
  number: number;
  name: string;
  fatherName: string;
  dob: string;
  isChecked: boolean;
  filePath: string;
}

export class IdentityProof {
  id: number;
  identityProofTypeId: number;
  mame: string;
  isChecked: boolean;
  number: number;
  isRequired: boolean;
  imgUrl: string;
  filePath: any;
  fileNames: any[];
}

export class AddressProofs {
  isValid: boolean;
  lstAddressProofs: IdentityProof[];
}

export class AddressProof {
  id: number;
  addressProofTypeId: number;
  name: string;
  isChecked: boolean;
  number: number;
  imgUrl: string;
  filePath: any;
  fileNames: any[];
}
export class MasterModel {
  id: number;
  name: string;
  baseId: number;
}
export class GroupEnterpriseResponse {
  id: number;
  enterpriseId: string;
  enterpriseName: string;
}
export class AddressTypes {
  id: number;
  name: number;
}
export class EducationTypes {
  id: number;
  name: number;
}

export class EmployementTypes {
  id: number;
  name: number;
}
export class ProgramTypes {
  id: number;
  name: number;
}
export class Gender {
  id: number;
  name: number;
}

export class ProfileAddress {
  id: number;
  profileCheckTypeId: number;
  addressProofTypeId: number;
  profileCheckType: string;
  city: string;
  postalCode: string;
  landMark1: string;
  landMark2: string;
  landLineTelephone: string;
  periodFrom: string;
  periodTo: string;
  profile_ID: number;
  isFormValid: false;
  otherAddressProof: string;
  filePath: string;
  contactPersonName: string;
  contactPersonNumber: string;
  relationWithContactPerson: string;
  countryId: number;
  stateId: number;
  address: string;
  addressType: string;
}

export class Enterprises {
  id: number;
  name: string;
  isDeleted: boolean;
  createdBy: number;
  createdDate: Date;
  updatedBy: number;
  updatedDate: Date;
  addressLine1: string;
  addressLine2: string;
  city: string;
  addressStateCode: string;
  addressPostalCode: string;
  contactPhone: string;
  contactEmail: string;
  contactFax: string;
  enterpriseCode: string;
  isTest: boolean;
  contactFirstName: string;
  contactLastName: string;
}

export class ProfileContactDetails {
  id: number;
  email: string;
  mobileNo: string;
  home: string;
  createdBy: number;
  createdDate: Date;
  modifiedDate: Date;
  modifiedBy: number;
  profile_ID: number;
}

export class ProfileEmpDetails {
  id: number;
  companyName: string;
  companyTelephone: string;
  website: string;
  rptBranchAddress: string;
  fromDate: string;
  toDate: string;
  positionHeld: string;
  department: string;
  employeeCode: string;
  sSN: string;
  employementTypeId: number;
  agencyName: string;
  agencyDetails: string;
  reasonForLeaving: string;
  startingBaseSalary: string;
  otherCompansation1: string;
  total1: string;
  finalBaseSalary: string;
  otherCompansation2: string;
  total2: string;
  isCurrentEmployement: boolean;
  verificationInitiatedDate: string;
  otherDetails: string;
  createdBy: number;
  createdDate: Date;
  updatedDate: Date;
  updatedBy: number;
  profile_ID: number;
  employerTypeId: number;
  employerType: string;
  countryId: number;
  stateId: number;
  city: string;
  zipCode: string;
  address: string;
  email: string;
  reportingManagerDetails: ReportingManagerDetails;
  // referenceDetails: ProfileReferenceDetails[];// seperating reference -- praveen
  filePath: string;
  profileCheckTypeId: number;
  profileCheckType: string;
  isEmployementOversease: boolean;
}

export class ProfileNameChangeDetails {
  id: number;
  profile_ID: number;
  changedDate: string;
  firstName: string;
  lastName: string;
  middleName: string;
  surName: string;
  filePath: string;
  fileName: string;
  createdBy: number;
  createdDate: Date;
  updatedDate: Date;
  updatedBy: number;
  statusType_ID: number;
}

export class ProfilePassportDetails {
  id: number;
  number: number;
  placeOfIssue: String;
  issueDate: string;
  expiryDate: string;
  createdBy: number;
  createdDate: Date;
  modifiedDate: Date;
  modifiedBy: number;
  profile_ID: number;
  isActive: boolean;
  filePath: string;
  isChecked: boolean;
}

export class ProfilePersonDetails {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  surName: string;
  fatherFirstName: string;
  fatherLastName: string;
  fatherMiddleName: string;
  fatherSurName: string;
  isNameChanged: boolean;
  gender: string;
  dob: string;
  nationality: string;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  profile_ID: number;
  standerdName: string;
  maritulStatus: string;
  uan: string;
  address: string;
  presentAddress: string;
  nameChangeDetails: ProfileNameChangeDetails = new ProfileNameChangeDetails();
  profileContactDetails: ProfileContactDetails = new ProfileContactDetails();

  email: string;
  mobileNo: string;
  lstResumes: Array<string>;
  resumeFilePath: string;
}
export class ProfileQualificationExt {
  id: number;
  gapFromDate: string;
  gapToDate: string;
  reason: string;
  profileId: number;
  isChecked: true;
  lstProfileQualification: ProfileQualificationDetails[];
}

export class ProfileQualificationDetails {
  id: number;
  educationTypeId: number;
  EducationType: string;
  fromYear: number;
  toYear: number;
  graduationDate: string;
  specilization: string;
  isGraduated: boolean;
  programTypeId: number;
  institutionName: string;
  address: string;
  telephoneNo: string;
  city: string;
  stateId: number;
  countryId: number;
  zipCode: string;
  regorRollNo: string;
  copyOfCertificatesAttached: boolean;
  educationInOverseas: boolean;
  sSNorTIN: string;
  givenNameAtOverseas: string;
  createdBy: number;
  createdDate: Date;
  updatedDate: Date;
  updatedBy: number;
  profile_ID: number;
  universityName: string;
  universityAddress: string;
  universityTelephoneNo: string;
  universityCity: string;
  universityStateId: number;
  universityCountryId: number;
  lstCerificates: ProfileQualificationCertificates[];
  profileCheckTypeId: number;
  profileCheckType: string;
  specialization: string;
}

export class ProfileQualificationCertificates {
  id: number;
  profileQualificationDetailId: number;
  fileName: string;
  filePath: string;
  educationTypeId: number;
  statusTypeId: number;
}

export class ProfileReferenceDetails {
  id: number;
  profile_ID: number;
  name: string;
  desigination: string;
  companyName: string;
  companyTelephone: string;
  companyEmail: string;
  howDoyoyKnowThisPerson: string;
  canReferencebeContacted: boolean;
  isTheReferenceLinkedToEmployement: boolean;
  mentionDateReference: string;
  aditionalInformation: string;
  createdBy: number;
  createdDate: Date;
  updatedDate: Date;
  updatedBy: number;
  profileCheckTypeId: number;
  profileCheckType: string;
  randomString: string;
  empDetailId: number;
  employerName: string;
}

export class ProfileLogs {
  remarks: string;
  profileStatusTypeId: number;
  profileIds: Array<number>;
  profileId: number;
  isChange: boolean;
  idChecks: Array<number>;
}
export class CheckLogs {
  remarks: string;
  checkStatusTypeId: number;
  profileId: number;
  isChange: boolean;
  globalCheckId: number;
  perfomedWfActionId: number;
}
export class ReviewLogs {
  checkId: number;
  profileId: number;
  statusId: number;
  status: string;
  createdDate: Date;
  createdByUser: string;
  remarks?: string;
}

export class HrconfirmReqModel {
  id: number;
  profileId: number;
  checkId: number;
  checkTypeId: number;
  hrconfirmReqStatusTypeId: number;
  hRConfirmTypeId: number;
  hrconfirmSubTypeId: number;
  remarks: string;
  checkStatusTypeId: number;
  perfomedWfActionId: number;
  isChange: boolean;
  ccMails: string;
  bccMails: string;
  caseId: string;
  extraPrice: number
}

export class CheckAssignedToLogs {
  remarks: string;
  assignedTo: number;
  profileId: number;
  isChange: boolean;
  globalCheckId: number;
}
export class ProfileUserRequests {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobileNo: string;
  userName: string;
  password: string;
  enterpriseName: string;
  role: string;
  userType: string;
}
export class Profiles extends PageSettings {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  enterprise: string;
  profileStatus: string;
  mobileNo: string;
  userName: string;
  enterpriseName: string;
  createdDate: Date;
  lastModifiedDate: Date;
  profileStatusTypeId: number;
  enterpriseId: number;
  profileChecks: string;
  lstProfileWiseChecks: ProfileWiseChecks[];
}
export class ReportIndex {
  id: number;
  dateOfReport: string;
  clientReference: string;
  levelOfCheck: string;
  finalReportTypeId: string;
  finalRptColorId: number;
}

export class ProfileListSearch extends PageSettings {
  id: number;
  firstName: string;
  lastName: string;
  profileNumber: string;
  email: string;
  employeeCode: string;
  enterprise: string;
  enterpriseCode: string;
  profileStatus: string;
  mobileNo: string;
  userName: string;
  enterpriseName: string;
  fromDate: string;
  toDate: string;
  fromModifiedDate: string;
  toModifiedDate: string;
  profileStatusTypeId: number;
  enterpriseId: number;
  enterpriseIdsList: number[];
  profileChecks: string;
  ProjectName: string;
  projectId: number;
  locationId: number;
  Location: string;
  finalReportPath: string;
  finalRptColorId: number;
  finalReportTypeId: number;
  ReviewStatusTypeId: number;
  screenType: string;

  dateStatusTypeId: number;
  statusFromDate: string;
  statusToDate: string;
  ageFrom: number;
  ageTo: number;
  pendingFrom: number;
  pendingTo: number;
  lstProfileWiseChecks: ProfileWiseChecks[];
  selectedProfileStatusTypes: Array<number>;
  selectedReviewStatusTypes: Array<number>;
}

export class ProfileList extends PageSettings {
  id: number;
  firstName: string;
  lastName: string;
  profileNumber: string;
  email: string;
  employeeCode: string;
  enterprise: string;
  enterpriseCode: string;
  profileStatus: string;
  mobileNo: string;
  userName: string;
  enterpriseName: string;
  createdDate: Date;
  lastModifiedDate: Date;
  profileStatusTypeId: number;
  enterpriseId: number;
  profileChecks: string;
  ProjectName: string;
  Location: string;
  bgColorClass: string;
  icon: string;
  finalReportPath: string;
  finalRptColorId: number;
  finalReportTypeId: number;
  statusDisplayText: string;
  screenType: string;
  reviewStatusTypeId: number;
  reviewStatusTypeName: string;
  reviewWorkAvailableActions: string;
  lstProfileWiseChecks: ProfileWiseChecks[];
  selectedProfileStatusTypes: Array<number>;
  selectedReviewStatusTypes: Array<number>;
  idChecks: Array<number>;
  eIdChecks: Array<number>;
  profileInitiatedDate: Date;

  initiatedAge: number;
  cssRowClass: string;
  pendingChecksCount: number;

}

export class ReportingManagerDetails {
  id: number;
  profileEmpDetail_ID: number;
  name: string;
  jobTitle: string;
  department: string;
  contactNumber: string;
  email: string;
  statusType_ID: number;
}

export class UserRequests extends PageSettings {
  id: number;
  userID: number;
  parentId: number;
  userName: string;
  enterpriseID: number;
  isSendMail?: boolean;
  enterpriseCode: string;
  email: string;
  mobileNo: string;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  statusTypeID: number;
  firstName: string;
  enterpriseId: number;
  enterpriseIdsList: number[];
  filterEnterpriseId: number;
  lastName: string;
  roleID: number;
  userTypeID: number;
  password: string;
  confirmPassword: string;
  role: string;
  userType: string;
  employeeCode: string;
  profileStatusTypeId: number;
  assignedEnterprises: any;
  mailTypeIds: Array<number>;
  selectedProfileStatusTypes: Array<number>;
  ProjectName: string;
  Location: string;
  finalRptColorId: number;
}
export class UserListResponse extends PageSettings {
  id: number;
  userID: number;
  parentId: number;
  userName: string;
  enterpriseID: number;
  isSendMail?: boolean;
  enterpriseCode: string;
  email: string;
  mobileNo: string;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  statusTypeID: number;
  firstName: string;
  enterpriseId: number;
  lastName: string;
  roleID: number;
  userTypeID: number;
  password: string;
  confirmPassword: string;
  role: string;
  userType: string;
  employeeCode: string;
  profileStatusTypeId: number;
  assignedEnterprises: any;
  mailTypeIds: Array<number>;
  selectedProfileStatusTypes: Array<number>;
  ProjectName: string;
  Location: string;
  finalRptColorId: number;
  parentUser: UserRequests;
  groupEnterpriseIds: Array<number>;
}

export class UserAssignedToRequest {
  enterpriseId: number;
}
export class UserAssignedToResponse {
  assignedToUserList: Array<UserListResponse>;
}

export class ProfileConfig {
  id: number;
  profileGuid: string;
  firstName: String;
  lastName: String;
  middleName: String;
  email: string;
  mobileNo: string;
  enterpriseId: number;
  isSendMail: boolean;
  employeeCode: string;
  enterpriseCode: string;
  projectId: number;
  locationId: number;
  ccMails: string;
  bccMails: string;
  isReInitiateProfile: boolean;
  remarks: string;
  lstProfileChecksConfigs: ProfileChecksConfig[];
}

export class ProfileConfigResponse {
  id: number;
  profileGuid: string;
  firstName: String;
  lastName: String;
  middleName: String;
  email: string;
  mobileNo: string;
  enterpriseId: number;
  isSendMail: boolean;
  employeeCode: string;
  enterpriseCode: string;
  projectId: number;
  locationId: number;
  ccMails: string;
  bccMails: string;
  isReInitiateProfile: boolean;
  remarks: string;
  createdDate: Date;
  profileNumber: string;
  enterprise: string;
  profileStatus: string;
  userName: string;
  enterpriseName: string;
  profileChecks: string;
  ProjectName: string;
  Location: string;
  bgColorClass: string;
  icon: string;
  finalRptColorId: number;
  profileStatusTypeId: number;
  statusDisplayText: string;

  lstProfileChecksConfigs: ProfileChecksConfig[];
}

export class ProfileChecksConfig {
  id: number;
  profileCheckTypeId: number;
  profileCheckType: string;
  isChecked: boolean;
  path: string;
  isTab: boolean;
  isHideToClient: boolean;
  max: number;
  isDisableAddItem: boolean;
  isDisableRemoveItem: boolean;
  checkPrise: number;
  parentId: number;
}

export class ProfileChecksConfigMiniResponse {
  id: number;
  firstName: string;
  lastName: string;
  enterpriseName: string;
  employeeCode: string;
  profileNumber: string;
  enterpriseId: number;
  profileStatus: string;
  profileStatusTypeId: number;
  profileCreatedDate: Date;
  checksConfigs: ChecksConfigResponse[];
}

export class ChecksConfigResponse {
  id: number;
  name: string;
  items: number;
  modifiedDate: Date;
  createdDate: Date;
  createdBy: string;
  modifiedBy: string
  configHistories: ProfileChecksConfigHistoryResponse[];
}

export class ProfileChecksConfigHistoryResponse {
  prevCount: number;
  presentCount: number;
  ModifiedDate: Date;
  ModifiedBy: string;
}

export class AddressConfig {
  id: number;
  addressTypeId: number;
  addressType: string;
  isChecked: string;
}
export class EducationConfig {
  id: number;
  educationTypeId: number;
  educationType: string;
  isChecked: string;
}
export class EmployerConfig {
  id: number;
  employerTypeId: number;
  employerType: string;
  isChecked: string;
}
export class ReferenceConfig {
  id: number;
  referenceTypeId: number;
  referenceType: string;
  isChecked: string;
}
export class ProfileFormDeclaration {
  id: number;
  name: string;
  filePath: string;
  fileName: string;
  submittedDate: Date;
}

export class ProfileChecksTabs {
  label: string;
  aliasName: string;
  path: string;
  disabled: boolean;
  profileCheckTypeId;
}

export class GlobalCheckTypes {
  id: number;
  name: string;
  aliasName: string;
  parentId: number;
  parentCheck: string;
  groupId: number;
  hasWorkflow: string;
  isDefault: string;
  isTab: boolean;
  caseSufix: string;
  isHideToClient: boolean;
  isSkipVerify: boolean;
  max: number;
  navUrl: string;
  orderOfCheck: number;
  isShowDelete: boolean;
  template: string;
  objectKey: string;
  props: string;
}

export class GlobalChecksRequest {
  id: number;
  name: string;
}

export class ProfileWiseChecks {
  name: string;
  path: string;
  icon: boolean;
  wfActions: string;
  globalCheckId: number;
  items: number;
  checkStatusType: string;
  checkStatusTypeID: number;
  assignedTo: number;
}

export class UserRelatedResponseModel {
  userID: number;
  firstName: string;
  lastName: string;
  mobileNo: string;
  email: string;
  userName: string;
}

export class FileDetailsRequestModel {
  filePath: string;
  fileName: string;
  statusTypeId: number;
}
export class JSONData {
  width: string;
  height: string;
  order: number;
  imageInfo: string;
  amount: number;
}
export class ProfileVerifiedDocs {
  id: number;
  filePath: string;
  fileName: string;
  statusTypeId: number;
  docOrder: number;
  jsonData: JSONData = new JSONData();
}

export class VerifiedDocsRequest {
  lstCheckDocs: ProfileVerifiedDocs[];
  globalCheckId: number;
  profileId: number;
  checkId: number;
  enterpriseId: number;
  enterpriseCode: string;
}

export class VerifiedDocsProps {
  globalCheckId: number;
  profileId: number;
  checkId: number;
  enterpriseId: number;
  enterpriseCode: string;
}

export class ProfileCheckDocs {
  checkId: number;
  checkName: string;
  lstProfileVerifiedDocs: ProfileVerifiedDocs[];
}

export class ReportModel {
  content: any;
  profileNumber: string;
}

export class ProfileCheckResponse {
  caseId: string;
  name: string;
  assignedTo: string;
  checkTypeId: number;
  checkTypeName: string;
  items: number;
  wfStatusTypeId: number;
  wfStatusTypeName: string;
  profileNumber: string;
  firstName: string;
  lastName: string;
  profileId: string;
  checkPrice: number;
  CheckId: number;
  rhs?: string
}

export class ProjectLocationRequest {
  projectId: number;
  locationId: number;
}

export class TriggerMailsRequest {
  loggedInUserId: number;
  enterpriseId: number;
  profileIds: Array<number>;
  remarks: string;
}

export interface ProfileReminder {
  headingText: string;
  buttonText: string;
  profileIds: Array<any>;
  isChange: boolean;
  remarks: string;
}
export class ProfileChecksInfoRequest {
  profileIds: Array<number>;
}
export class AddExtraChargesRequest {
  checkId: number;
  profileId: number;
  globalCheckTypeId: number;
  extraCharges: Array<ExtraCharges>;
}
export class ExtraCharges {
  path: string;
  amt: number;
  cmts: string;
  dt: Date;
}

export class ProfileCheckInfoRequest {
  profileGuid: any;
  enterpriseId: number;
  profileId: number;
}
