import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommonService } from "src/app/common/services/common.service";
import { CommonDataService } from "../../../common/services/commonData.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class ApplicantService {
  constructor(
    private _commonDataService: CommonDataService,
    private _commonService: CommonService
  ) { }
  getChecksTabs<T>(id: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetChecksTabs?profileGuid=${id}`);
  }
  getProfileStatusTypes<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetProfileStatusTypes");
  }
  getProfileReviewStatusTypes<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetProfileReviewStatusTypes")
      ;
  }
  getCheckStatusTypes<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetCheckStatusTypes")
      ;
  }
  getAddressProofTypes<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetAddressProofTypes")
      ;
  }
  getProfileUserRequests<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetProfileUserRequests", data)
      ;
  }
  getProfilesList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetProfilesList", data)
      ;
  }

  getEnterpriseProfileList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/getEnterpriseProfileList", data)
      ;
  }

  getProfileById<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetProfileById", data)
      ;
  }

  ExportProfileUserRequests<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Report/ExportProfileUserRequests", data)
      ;
  }

  getAddressTypes<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/Profile/getAddressTypes")
      ;
  }

  getIdentityProofTypes<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/Profile/getIdentityProofTypes")
      ;
  }
  getEducationTypes<T>(): Observable<T[]> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetEducationTypes")
      ;
  }
  getEmployeementTypes<T>(): Observable<T[]> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetEmployementTypes")
      ;
  }
  getProgramTypes<T>(): Observable<T[]> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetProgramTypes")
      ;
  }

  getGenderTypes<T>(): Observable<T[]> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetGenderTypes")
      ;
  }

  getPersonalDetails<T>(id: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetPersonalDetails?profileGuid=${id}`);
  }

  getContactDetails<T>(id: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetContactDetails?profileGuid=${id}`);
  }
  getPassportDetails<T>(id: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetPassportDetails?profileGuid=${id}`);
  }
  getAddressDetails<T>(id: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetAddressDetails?profileGuid=${id}`);
  }
  // getIdentityProofs<T>(id: any): Observable<T> {
  //   return this._commonDataService
  //     .getDataAsync(`api/Profile/GetIdentityProofs?profileGuid=${id}`)
  //     .map(res => {
  //       return <T>res;
  //     });
  // }
  getIdentityDetails<T>(id: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetIdentityDetails?profileGuid=${id}`);
  }
  getAddressProofs<T>(id: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetAddressProofs?profileGuid=${id}`)
      ;
  }
  getQualificationDetails<T>(id: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetQualificationDetails?profileGuid=${id}`)
      ;
  }
  getEmpDetails<T>(id: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetEmpDetails?profileGuid=${id}`)
      ;
  }
  getReferenceDetails<T>(id: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetReferenceDetails?profileGuid=${id}`)
      ;
  }

  UpSertPersonalDetails<T>(id: any, data: any): Observable<T> {
    return this._commonDataService
      .patchDataAsync(
        `api/Profile/UpSertPersonalDetails?profileGuid=${id}`,
        data
      )
      ;
  }
  upSertContactDetails<T>(id: any, data: any): Observable<T> {
    return this._commonDataService
      .patchDataAsync(
        `api/Profile/UpSertContactDetails?profileGuid=${id}`,
        data
      )
      ;
  }
  upSertPassportDetails<T>(id: any, data: any): Observable<T> {
    return this._commonDataService
      .patchDataAsync(
        `api/Profile/UpSertPassportDetails?profileGuid=${id}`,
        data
      )
      ;
  }
  upSertAddressDetails<T>(id: any, data: any[]): Observable<T> {
    return this._commonDataService
      .patchDataAsync(
        `api/Profile/UpSertAddressDetails?profileGuid=${id}`,
        data
      )
      ;
  }
  UpSertProfileAddressProofs<T>(id: any, data: any[]): Observable<T> {
    return this._commonDataService
      .patchDataAsync(
        `api/Profile/UpSertProfileAddressProofs?profileGuid=${id}`,
        data
      )
      ;
  }
  // upSertIdentityproofDetails<T>(id: any, data: any[]): Observable<T> {
  //   return this._commonDataService
  //     .patchDataAsync(
  //       `api/Profile/UpSertIdentityproofDetails?profileGuid=${id}`,
  //       data
  //     )
  //     .map(res => {
  //       return <T>res;
  //     });
  // }
  upSertIdentityDetails<T>(id: any, data: any): Observable<T> {
    return this._commonDataService
      .patchDataAsync(
        `api/Profile/UpSertIdentityDetails?profileGuid=${id}`,
        data
      )
      ;
  }
  upSertQualificationDetails<T>(id: any, data: any): Observable<T> {
    return this._commonDataService
      .patchDataAsync(
        `api/Profile/UpSertQualificationDetails?profileGuid=${id}`,
        data
      )
      ;
  }
  upSertEmployeeDetails<T>(id: any, data: any[]): Observable<T> {
    return this._commonDataService
      .patchDataAsync(
        `api/Profile/UpSertEmployeeDetails?profileGuid=${id}`,
        data
      )
      ;
  }
  upSertReferenceDetails<T>(id: any, data: any[]): Observable<T> {
    return this._commonDataService
      .patchDataAsync(
        `api/Profile/UpSertReferenceDetails?profileGuid=${id}`,
        data
      )
      ;
  }

  getProfileConfig<T>(enterpriseId: number, id: string): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/GetProfileConfig?enterprieId=${enterpriseId}&&profileGuid=${id}`
      )
      ;
  }
  GetProfileMiniConfig<T>(enterpriseId: number, id: string): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/GetProfileMiniConfig?enterprieId=${enterpriseId}&&profileGuid=${id}`
      )
      ;
  }
  addProfileConfig<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/AddProfileConfig`, data)
      ;
  }
  deleteProfile<T>(id: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/DeleteProfile?id=${id}`)
      ;
  }
  getFormDeclaration<T>(id: string): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetFormDeclaration?profileGuid=${id}`)
      ;
  }
  getFormDeclarationPdf<T>(id: string): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetFormDeclarationPdf?profileGuid=${id}`)
      ;
  }
  upSertFormDeclaration<T>(id: any, data: any): Observable<T> {
    return this._commonDataService
      .patchDataAsync(
        `api/Profile/UpSertFormDeclaration?profileGuid=${id}`,
        data
      )
      ;
  }
  changeProfileStatus<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/ChangeProfileStatus`, data)
      ;
  }
  getProfileLogs<T>(profileId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetProfileLogs?id=${profileId}`)
      ;
  }
  downloadProfilePdf<T>(profileId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/DownloadProfilePdf?profileGuid=${profileId}`)
      ;
  }
  downloadProfileExcel<T>(profileId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/DownloadProfileExcel?profileGuid=${profileId}`)
      ;
  }

  getProfileDocs<T>(profileId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetProfileDocs?profileGuid=${profileId}`)
      ;
  }

  getProfileChecksByProfileId<T>(profileId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/GetProfileChecksByProfileId?profileId=${profileId}`
      )
      ;
  }

  getCheckWfLogs<T>(globalCheckId: number, profileId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/GetCheckWfLogs?globalCheckId=${globalCheckId}&profileId=${profileId}`
      )
      ;
  }
  getReviewLogs<T>(profileId: number, checkTypeId: number, checkId: number,): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/${profileId}/type/${checkTypeId}/check/${checkId}/logs`
      );
  }
  changeCheckStatus<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/ChangeCheckStatus", data)
      ;
  }

  addCheckRemarks<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/AddCheckRemarks", data)
      ;
  }

  changeCheckStatusV2<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/ChangeCheckStatusV2", data)
      ;
  }

  getCheckAssignedToLogs<T>(
    globalCheckId: number,
    profileId: number
  ): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/GetCheckAssignedToLogs?globalCheckId=${globalCheckId}&profileId=${profileId}`
      )
      ;
  }
  assignCheck<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/AssignCheck", data)
      ;
  }
  getAssignedToUsers<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetAssignedToUsers")
      ;
  }

  upSertProfileVerifiedDocs<T>(request: any
  ): Observable<T> {
    return this._commonDataService
      .postDataAsync(
        `api/Profile/UpSertProfileVerifiedDocs`,
        request
      )
      ;
  }

  getProfileVerifiedDocs<T>(
    profileId: number,
    globalCheckId: number,
    checkId: number
  ): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/GetProfileVerifiedDocs?globalCheckId=${globalCheckId}&profileId=${profileId}&checkId=${checkId}`
      )
      ;
  }

  downloadFinalReport<T>(profileGuid: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/DownloadFinalReport?profileGuid=${profileGuid}`
      )
      ;
  }
  previewCheckReport<T>(request: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(
        `api/Profile/PreviewCheckReport`, request
      )
      ;
  }

  getQualificationList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetEducationList", data)
      ;
  }

  getIdentityList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetIdentityList", data)
      ;
  }

  getEmployementList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetEmployementList", data)
      ;
  }
  getRhs<T>(checkId: number, globalCheckId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/GetRHS?checkId=${checkId}&globalCheckTypeId=${globalCheckId}`
      )
      ;
  }

  upSertRhs<T>(model: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/UpSertRhs`, model)
      ;
  }
  getReportIndex<T>(profileId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetReportIndex?profileId=${profileId}`)
      ;
  }
  updateReportIndex<T>(model: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/UpdateReportIndex`, model)
      ;
  }
  getAnnexureStatuses<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetAnnexureStatuses`)
      ;
  }
  changeAnnexureStatus<T>(
    checkId: number,
    annexureStatusId: number,
    globalCheckTypeId: number
  ): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/ChangeAnnexureStatus?checkId=${checkId}&annexureStatusId=${annexureStatusId}&globalCheckTypeId=${globalCheckTypeId}`
      )
      ;
  }

  updateConsideredIdentity<T>(request: any
  ): Observable<T> {
    return this._commonDataService
      .postDataAsync(
        `api/Profile/UpdateConsideredIdentity`, request
      )
      ;
  }

  getCheckMinInfo<T>(request: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(
        `api/Profile/GetProfileChecksMinInfo`, request
      )
      ;
  }

  getProfileGuidByProfileId<T>(profileId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/getProfileGuidByProfileId?profileId=${profileId}`
      )
      ;
  }
  getProfileDocumentTypes<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetProfileDocumentTypes`)
      ;
  }
  getProfileDocuments<T>(profileGuid: any): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Profile/getProfileDocuments?profileGuid=${profileGuid}`
      )
      ;
  }
  upSertProfileDocuments<T>(profileGuid: any, data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(
        `api/Profile/UpSertProfileDocuments?profileGuid=${profileGuid}`,
        data
      )
      ;
  }

  postFinalReport<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/SaveFinalReport", data)
      ;
  }

  getFinalReportContent<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/GetFinalReportContent`, data)
      ;
  }

  TriggerProfileReminders<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/trigger-profile-reminders`, data)
      ;
  }

  DownloadProfileCheckInfo(data: any, fileName: string) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/DownloadProfileChecksMinInfo", data)
      .subscribe((res: any) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
      });
  }

  getEducationListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetEducationListExport", data)
      .subscribe((res: any) => {
        this._commonService.downLoadExcel(res, 'EducationList.xlsx');
      });
  }

  getEmployementListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetEmployementListExport", data)
      .subscribe((res: any) => {
        this._commonService.downLoadExcel(res, 'Employement.xlsx');
      });
  }
  getAddressListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetAddressListExport", data)
      .subscribe((res: any) => {
        this._commonService.downLoadExcel(res, 'Address.xlsx');
      });
  }
  getCheckListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetCheckListExport", data)
      .subscribe((res: any) => {
        console.log(data);
        this._commonService.downLoadExcel(res, 'Check.xlsx');
      });
  }
  getIdentityListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetIdentityListExport", data)
      .subscribe((res: any) => {
        this._commonService.downLoadExcel(res, 'Identity.xlsx');
      });
  }
  addExtraCharges<T>(request: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(
        `api/Profile/AddExtraCharges`, request
      )
      ;
  }
  getProfilesListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Report/GetProfilesListExport", data)
      .subscribe((res: any) => {
        this._commonService.downLoadExcel(res, 'Profile.xlsx');
      });
  } 
  exportEnterpriseProfileList(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Report/ExportEnterpriseProfileList", data)
      .subscribe((res: any) => {
        this._commonService.downLoadExcel(res, 'Profile.xlsx');
      });
  } 

  getShareFinalReportMailContent<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/GetShareFinalReportMailContent`, data)
      ;
  }

  addShareFinalReport<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/AddShareFinalReport`, data)
      ;
  }

  changeReportStatusType<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/ChangeReportStatusType`, data)
      ;
  }

  getFinalReportUploadLogs<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/GetFinalReportUploadLogs`, data)
      ;
  }

  getFinalReportDownloadLogs<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/GetFinalReportDownloadLogs`, data)
      ;
  }

  deleteFinalReport<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/deleteFinalReport`, data)
      ;
  }
  getFinalReportReviewLogs<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/GetFinalReportReviewLogs`, data)
      ;
  }

  getShareFinalReportLogs<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/GetShareFinalReportLogs`, data)
      ;
  }

  addFinalReportDownloadLog<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/AddFinalReportDownloadLog`, data)
      ;
  }

  getShareDownloadLogs<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/GetShareDownloadLogs`, data)
      ;
  }


  getCheckList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetCheckList", data)
      ;
  }
  getCrvList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetCrvList", data)
      ;
  }
  getCrvListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetCrvListExport", data)
      .subscribe((res: any) => {
        console.log(data);
        this._commonService.downLoadExcel(res, 'CrvList.xlsx');
      });
  }
  getAddressList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetAddressList", data)
      ;
  }


  getCreditCibilList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetCreditCibilList", data)
      ;
  }
  getCreditCibilListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetCreditCibilListExport", data)
      .subscribe((res: any) => {
        console.log(data);
        this._commonService.downLoadExcel(res, 'CreditCibilList.xlsx');
      });
  }

  getGlobalDatabaseList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetGlobalDatabaseList", data)
      ;
  }
  getGlobalDatabaseListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetGlobalDatabaseListExport", data)
      .subscribe((res: any) => {
        console.log(data);
        this._commonService.downLoadExcel(res, 'GlobalDatabaseList.xlsx');
      });
  }

  getreferenceList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetreferenceList", data)
      ;
  }
  getreferenceListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetreferenceListExport", data)
      .subscribe((res: any) => {
        console.log(data);
        this._commonService.downLoadExcel(res, 'ReferenceList.xlsx');
      });
  }

  getDrugList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetDrugList", data)
      ;
  }
  getDrugListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetDrugListExport", data)
      .subscribe((res: any) => {
        console.log(data);
        this._commonService.downLoadExcel(res, 'DrugList.xlsx');
      });
  }

  getPoliceList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetPoliceList", data)
      ;
  }
  getPoliceListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Profile/GetPoliceListExport", data)
      .subscribe((res: any) => {
        console.log(data);
        this._commonService.downLoadExcel(res, 'PoliceList.xlsx');
      });
  }

  sendClarificationRequest<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Clarification/SendClarificationRequest", data)
      ;

  }

  exportFinalReport(data: any) {
    this._commonDataService
      .ExternalPostDataNoTokenBlobAsync(`${environment.pdfUrl}api/pdfReport/ReportV2`, data)
      .subscribe((res: any) => {
        this._commonService.downLoadExcel(res, data.profileNumber + '.pdf');
      });
  }

  pdfToImageConverter(data: any) {
    this._commonDataService
      .ExternalPostDataNoTokenBlobAsync(`${environment.pdfUrl}api/pdfReport/PdfToImageConverter`, data)
      .subscribe((res: any) => {
        this._commonService.downLoadExcel(res, data.profileNumber + '.pdf');
      });
  }

  getPendingCheckInfo<T>(profileId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetProfilePendingChecksList?profileId=` + profileId)
      ;
  }

  uploadBulkPdf<T>(formData: any, fileType: string): Observable<T> {
    return this._commonDataService
      .postUploadDataAsync(`api/Profile/UploadFile`, formData, fileType);
  }

  getCheckReviewTypes(): Observable<any> {
    return this._commonDataService
      .getDataAsync(`api/Profile/CheckReviewTypes`);
  }

  updateCheckReviewStatus(profileId: number, checkTypeId: number, data: any) {
    return this._commonDataService
      .postDataAsync(`api/Profile/${profileId}/CheckType/${checkTypeId}/ReviewStatus`, data);
  }
}
