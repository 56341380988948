import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommonDataService } from "src/app/common/services/commonData.service";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class SharedService {
  constructor(private _commonDataService: CommonDataService) { }

  signOut<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/User/SignOut")
       .pipe(map(res => <T>res));
  }

  getMenuItems<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/Admin/GetMenuItems")
      .pipe(map(res => <T>res));
  }
  getUserTypes<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/User/GetUserTypes")
       .pipe(map(res => <T>res));
  }
  getGroupEnterprises<T>(enterpriseId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/User/GetGroupEnterprises?enterpriseId=" + enterpriseId)
       .pipe(map(res => <T>res));
  }
  updateGroupUserEnterprises<T>(request: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/User/UpdateGroupUserEnterprises", request)
       .pipe(map(res => <T>res));
  }
  getRoles<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/User/GetRoles")
       .pipe(map(res => <T>res));
  }
  getEnterPrises<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/User/GetEnterPrises")
       .pipe(map(res => <T>res));
  }
  getPrivilegeBasedOnNavUrl<T>(routeName: string): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/admin/GetPrivilegeBasedOnNavUrl?navUrl=${routeName}`)
       .pipe(map(res => <T>res));
  }
  getCountries<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetCountries")
       .pipe(map(res => <T>res));
  }
  getStates<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetStates`)
       .pipe(map(res => <T>res));
  }
  getCheckRolePrevBasedOnUrl<T>(
    routeName: string,
    globalCheckId: number
  ): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/admin/GetCheckRolePrevBasedOnUrl?navUrl=${routeName}&globalCheckId=${globalCheckId}`
      )
       .pipe(map(res => <T>res));
  }

  getProjects<T>(enterpriseId): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetProjects/` + enterpriseId)
       .pipe(map(res => <T>res));
  }
  getLocations<T>(enterpriseId): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/GetLocations/` + enterpriseId)
       .pipe(map(res => <T>res));
  }

  getEnterpriseInfo<T>(enterpriseId): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/User/GetEnterpriseInfo?enterpriseId=` + enterpriseId)
       .pipe(map(res => <T>res));
  }
  getHrConfirmReqStatusTypes<T>(): Observable<T[]> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetHrConfirmReqStatusTypes")
      .pipe(map(res => <T[]>res));
  }
  getInstantCheckTypes<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/instantprofiles/GetInstantCheckTypes")
       .pipe(map(res => <T>res));
  }

  getGSTTypes<T>(): Observable<T[]> {
    return this._commonDataService
      .getDataAsync("api/Profile/GetGSTTypes")
      .pipe(map((res) => <T[]>res));
  }
}
