import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProjectLocationRequest } from "src/app/common/models/applicant.class";
import { CommonService } from "src/app/common/services/common.service";
import { CommonDataService } from "src/app/common/services/commonData.service";
import { map } from 'rxjs/operators';


@Injectable({ providedIn: "root" })
export class AdminService {
  constructor(private _commonDataService: CommonDataService, private _commonService: CommonService) { }

  getRoleList<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/Admin/GetRoleList")
      .pipe(map(res => <T>res));
  }
  deleteRole<T>(roleId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/DeleteRole?roleId=${roleId}`)
       .pipe(map(res => <T>res));
  }

  getAppRolePreviliges<T>(roleId: number, isAdd: boolean): Observable<T> {
    return this._commonDataService
      .getDataAsync(
        `api/Admin/GetAppRolePreviliges?roleId=${roleId}&isAdd=${isAdd}`
      )
       .pipe(map(res => <T>res));
  }

  upsertAppPrivileges<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Admin/upsertAppPrivileges`, data)
       .pipe(map(res => <T>res));
  }

  getEnterpriseList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Admin/GetEnterpriseList", data)
      .pipe(map(res => <T>res));
  }
  getEnterprise<T>(id: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/GetEnterprise?id=${id}`)
       .pipe(map(res => <T>res));
  }

  upSertEnterprise<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Admin/UpSertEnterprise`, data)
       .pipe(map(res => <T>res));
  }

  deleteEnterprise<T>(id: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/DeleteEnterprise?id=${id}`)
       .pipe(map(res => <T>res));
  }

  getEnterpriseTypes<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/GetEnterpriseTypes`)
       .pipe(map(res => <T>res));
  }
  downloadBulkFile<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/DownloadBulkFile`)
       .pipe(map(res => <T>res));
  }

  getCheckRolePreviliges<T>(roleId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/getCheckRolePreviliges?roleId=${roleId}`)
       .pipe(map(res => <T>res));
  }
  upsertCheckPrivileges<T>(data: any, roleId): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Admin/UpsertCheckPrivileges?roleId=${roleId}`, data)
       .pipe(map(res => <T>res));
  }

  getHolidayList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/TAT/GetHolidayList", data)
      .pipe(map(res => <T>res));
  }
  getHoliday<T>(id: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/TAT/GetHolidayById?id=${id}`)
       .pipe(map(res => <T>res));
  }

  addUpdateHoliday<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/TAT/AddUpdateHoliday`, data)
       .pipe(map(res => <T>res));
  }

  deleteHoliday<T>(id: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/TAT/DeleteHoliday?id=${id}`)
       .pipe(map(res => <T>res));
  }

  getHolidayCategories<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/TAT/GetHolidayCategories`)
       .pipe(map(res => <T>res));
  }

  getEnterpriseProfileStatuses<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Dashboard/GetEnterpriseProfileStatuses`, data)
       .pipe(map(res => <T>res));
  }
  GetEnterpriseCheckStatuses<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Dashboard/GetEnterpriseCheckStatuses`, data)
       .pipe(map(res => <T>res));
  }

  GetEnterpriseDateWiseReport<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Dashboard/GetEnterpriseDateWiseStatus`, data)
       .pipe(map(res => <T>res));
  }

  getReminderCount<T>(data: any): Observable<T>{
    return this._commonDataService
      .postDataAsync(`api/Dashboard/GetReminderCount`, data)
      .pipe(map(res => <T>res));
  }

  getQualificationList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetEducationList", data)
       .pipe(map(res => <T>res));
  }

  getEmployementList<T>(data: any): Observable<T> {
    return this._commonDataService  
      .postDataAsync("api/Profile/GetEmployementList", data)
       .pipe(map(res => <T>res));
  }

  getAddressList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetAddressList", data)
       .pipe(map(res => <T>res));
  }

  getCheckList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Profile/GetCheckList", data)
       .pipe(map(res => <T>res));
  }

  getUserList<T>(filter: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/User/GetUsersList", filter)
       .pipe(map(res => <T>res));
  }

  getUserSessionList<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/Admin/GetUserSessionList", data)
      .pipe(map(res => <T>res));
  }

  getEnterpriseSettings<T>(enterpriseId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/GetEnterpriseSettings?enterpriseId=${enterpriseId}`)
      .pipe(map(res => <T>res));
  }

  setEnterpriseSettings<T>(etnerpriseSettings: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Admin/SetEnterpriseSettings`, etnerpriseSettings)
       .pipe(map(res => <T>res));
  }

  addUpdateProject<T>(projectRequest: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Admin/AddUpdateProject`, projectRequest)
       .pipe(map(res => <T>res));
  }

  deleteProject<T>(projectId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/DeleteProject/${projectId}`)
       .pipe(map(res => <T>res));
  }

  getProjectList<T>(projectSearchRequest: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Admin/GetProjectList`, projectSearchRequest)
       .pipe(map(res => <T>res));
  }

  getProjectById<T>(projectId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/GetProjectById/${projectId}`)
       .pipe(map(res => <T>res));
  }

  addUpdateLocation<T>(locationRequest: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Admin/AddUpdateLocation`, locationRequest)
       .pipe(map(res => <T>res));
  }

  deleteLocation<T>(locationId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/DeleteLocation/${locationId}`)
       .pipe(map(res => <T>res));
  }

  getLocationList<T>(locationSearchRequest: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Admin/GetLocationList`, locationSearchRequest)
       .pipe(map(res => <T>res));
  }

  getLocationDetails<T>(locationId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Admin/GetLocationDetails/${locationId}`)
       .pipe(map(res => <T>res));
  }

  getProjectLocations<T>(request: ProjectLocationRequest): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Admin/GetProjectLocations`, request)
       .pipe(map(res => <T>res));
  }

  getProjectLocationList<T>(locationSearchRequest: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/GetProjectLocations`, locationSearchRequest)
       .pipe(map(res => <T>res));
  }

  deleteProjectLocation<T>(locationId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/DeleteProjectLocation/${locationId}`)
       .pipe(map(res => <T>res));
  }

  upsertProjectLocation<T>(locationRequest: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/UpsertProjectLocation`, locationRequest)
       .pipe(map(res => <T>res));
  }

  getGlobalChecks<T>(request:any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/profile/GetGlobalCheckTypes`,request)
       .pipe(map(res => <T>res));
  }

  addEditGlobalCheckTypes<T>(request: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/AddEditGlobalCheckTypes`, request)
       .pipe(map(res => <T>res));
  }

  deleteGlobalCheckType<T>(id: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Profile/DeleteGlobalCheckType/${id}`)
       .pipe(map(res => <T>res));
  }
  getEnterpriseProfileStatusesExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Dashboard/GetEnterpriseProfileStatusesExport", data)
      .pipe(map((res) => res)).subscribe((res: any) => {
        this._commonService.downLoadExcel(res, 'EnterprisesWise.xlsx');
      });
  }
  getEnterpriseListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/Admin/GetEnterpriseListExport", data)
      .subscribe((res: any) => {
        this._commonService.downLoadExcel(res, 'EnterprisesList.xlsx');
      });
  }
  getReportOrSendOtp<T>(data: any): Observable<T> {
    return this._commonDataService
      .postNoToken(`api/Public/get-report-or-send-otp`, data)
      .pipe(map(res => <T>res));
  }
  validateOtpGetReport<T>(data: any): Observable<T> {
    return this._commonDataService
      .PostDataNoTokenBlobAsync(`api/Public/validate-otp-get-report`, data)
      .pipe(map(res => <T>res));
  }

  getProfileInfo<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Profile/GetProfileInfo`, data)
      .pipe(map(res => <T>res));
  }

  getAggrements<T>(enterpriseId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Aggrement/GetAggrements?enterpriseId=${enterpriseId}`)
      .pipe(map(res => <T>res));
  }  
  getAggrement<T>(aggrementId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Aggrement/GetAggrement?aggrementId=${aggrementId}`)
      .pipe(map(res => <T>res));
  }  
  getAgreementPrices<T>(aggrementId: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/Aggrement/GetAgreementPrices?aggrementId=${aggrementId}`)
      .pipe(map(res => <T>res));
  }  
  insertOrUpdateAggrementPrices<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Aggrement/InsertOrUpdateAggrementPrices`, data)
       .pipe(map(res => <T>res));
  }
  createAgreement<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/Aggrement/createAgreement`, data)
       .pipe(map(res => <T>res));
  }
  updateAgreement<T>(data: any): Observable<T> {
    return this._commonDataService
      .patchDataAsync(`api/Aggrement/UpdateAgreement`, data)
       .pipe(map(res => <T>res));
  }
}
